import {
  Badge,
  Box,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import theme from "../../themes/light.ts";
import { useSpace } from "./SpaceProvider.tsx";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import { MouseEvent, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "react-query";
import { deleteSpaceApi } from "./useSpaces.ts";
import { useSnackbar } from "notistack";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import SpacesHeader from "./SpacesHeader.tsx";
import { SPACES_ROUTE } from "../../pages/Applications/Router";

const SpacesList = () => {
  const snackbar = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSpace, setSelectedSpace] = useState<string | undefined>();
  const {
    spacesList,
    spacesListLoading,
    spacesListFetching,
    spacesListRefetch,
  } = useSpace();
  const open = Boolean(anchorEl);
  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    space_id?: string
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedSpace(space_id);
    setAnchorEl(event.currentTarget);
  };
  const deleteMutation = useMutation(() => {
    if (selectedSpace) {
      return deleteSpaceApi(selectedSpace);
    }
    return Promise.reject();
  });

  const handleClose = async (event: MouseEvent<HTMLLIElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedSpace("");

    setAnchorEl(null);
  };

  return (
    <Stack height={"100vh"} sx={{ background: theme.palette.grey[50] }}>
      <SpacesHeader />
      {(spacesListLoading || spacesListFetching) && <LinearProgress />}
      <Grid
        container
        direction="row"
        justifyContent="start"
        alignItems="flex-start"
        sx={{
          padding: 4,
          // height: "100%",
          overflow: "auto",
          marginTop: "0",
        }}
        spacing={2}
      >
        <Grid item xs={2}>
          <Link
            to={`${SPACES_ROUTE}/add`}
            style={{ textDecoration: "none", color: theme.palette.grey[800] }}
          >
            <Box
              sx={(t) => ({
                width: "100%",
                height: "164px",
                border: `2px dashed ${t.palette.divider}`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "12px",
                transition: "0.8s all-ease",
                fontFamily: "Inter",
                svg: {
                  color: theme.palette.primary.main,
                },
                "&:hover, &:hover svg": {
                  background: theme.palette.primary.main,
                  color: theme.palette.background.default,
                  border: "none",
                },
              })}
            >
              <Stack justifyContent="center" alignItems="center">
                <AddIcon
                  height={"24px"}
                  width={"24px"}
                  sx={{ flexShrink: 0 }}
                />
                Create Space
              </Stack>
            </Box>
          </Link>
        </Grid>
        {spacesList?.map((space) => (
          <Grid item xs={2} key={space.id}>
            <Link
              to={`${SPACES_ROUTE}/${space.id}`}
              style={{ textDecoration: "none" }}
            >
              <Box
                sx={(t) => ({
                  padding: "8px 14px",
                  width: "100%",
                  height: "164px",
                  border: `2px solid ${t.palette.divider}`,
                  background: "white",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "space-between",
                  borderRadius: "12px",
                  cursor: "pointer",
                  "&:hover *": {
                    visibility: "visible",
                  },
                })}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="5px"
                >
                  <Stack
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                    direction="row"
                    flex={1}
                  >
                    <Typography variant="headline4" py={"8px"}>
                      {space.name}
                    </Typography>
                  </Stack>
                  <IconButton
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(e) => handleClick(e, space.id)}
                    sx={{
                      background: "none",
                      visibility: "hidden",
                      "&:hover": {
                        color: theme.palette.primary.main,
                        background: theme.palette.primary.light,
                      },
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id={`basic-menu-${space.id}`}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    sx={{
                      "& .MuiPaper-root": {
                        boxShadow: "2px 4px 7px -2px #c4c4c4",
                      },
                    }}
                  >
                    <MenuItem
                      onClick={(e) => {
                        deleteMutation
                          .mutateAsync()
                          .then(() => {
                            snackbar.enqueueSnackbar(
                              "space deleted successfully!",
                              {
                                variant: "success",
                              }
                            );
                          })
                          .catch((e) => {
                            snackbar.enqueueSnackbar("Error accrued: " + e, {
                              variant: "success",
                            });
                          })
                          .finally(() => {
                            spacesListRefetch();
                          });
                        handleClose(e);
                      }}
                      sx={{
                        color: theme.palette.error.main,
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <DeleteIcon color={"error"} />
                      Delete
                    </MenuItem>
                  </Menu>
                </Stack>
                <Stack
                  direction="row-reverse"
                  spacing={1}
                  justifyContent="space-between"
                >
                  <Box
                    sx={{
                      background: theme.palette.primary.light,
                      color: theme.palette.primary.main,
                      padding: "4px 8px",
                      borderRadius: "16px",
                      height: "24px",
                      whiteSpace: "nowrap",
                      fontSize: "10px",
                      fontFamily: 'Inter, Roboto, Helvetica, Arial, sans-serif',
                    }}
                  >
                    {space?.total_asins} ASINs
                  </Box>
                  {!!space?.total_suggested && (
                    <Link
                      to={`${SPACES_ROUTE}/${space.id}/?tabId=suggested_asins`}
                      style={{ textDecoration: "none" }}
                    >
                      <Tooltip
                        title={`You have ${space?.total_suggested} suggestion for this space`}
                      >
                        <Badge
                          color="secondary"
                          badgeContent={space?.total_suggested}
                        >
                          <TipsAndUpdatesIcon />
                        </Badge>
                      </Tooltip>
                    </Link>
                  )}
                  {/*<Box*/}
                  {/*  sx={{*/}
                  {/*    background: theme.palette.success.light,*/}
                  {/*    color: theme.palette.grey[800],*/}
                  {/*    padding: "4px 8px",*/}
                  {/*    borderRadius: "16px",*/}
                  {/*    height: "24px",*/}
                  {/*    whiteSpace: "nowrap",*/}
                  {/*    fontSize: "10px",*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  {space?.status}*/}
                  {/*</Box>*/}
                  {/*<Box*/}
                  {/*  sx={{*/}
                  {/*    background: theme.palette.grey[200],*/}
                  {/*    color: theme.palette.grey[800],*/}
                  {/*    padding: "4px 8px",*/}
                  {/*    borderRadius: "16px",*/}
                  {/*    height: "24px",*/}
                  {/*    whiteSpace: "nowrap",*/}
                  {/*    fontSize: "10px",*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  {space.type}*/}
                  {/*</Box>*/}
                </Stack>
              </Box>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default SpacesList;
